import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import {
  VideoCard,
  GuidelinesCard,
  Testimonials,
  JoinUs,
  StoryCard,
} from '../components';
import useFetch, { method } from '../hooks/useFetch';
import { urls } from '../services/urls';
import { useStateValue } from '../state';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
`;

export const AboutUsPage = () => {
  const [{ about: { testimonials } }, dispatch] = useStateValue();
  useFetch({
    url: urls.siteContent.testimonials,
    method: method.GET,
    condition: () => (!testimonials ? true : false),
    callback: data => dispatch({
      type: 'setTestimonials',
      newTestimonials: data,
    })
  });

  return (
    <>
      <Wrapper>
        <StoryCard />
        <VideoCard />
      </Wrapper>
      <GuidelinesCard />
      {testimonials && testimonials.length > 0 && <Testimonials content={testimonials} />}
      <Wrapper>
        <StyledContainer>
          <JoinUs />
        </StyledContainer>
      </Wrapper>
    </>
  );
};

export default AboutUsPage;

export const pageQuery = graphql`
  query AboutUsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
